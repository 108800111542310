// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const my = {
  dashboard: 'Home',
  my_loans: 'Pinjaman Saya',
  notification: 'Pemberitahuan',
  wait_while_profile_being_verified: 'Sila tunggu sementara profil anda sedang disahkan.',
  account: 'Akaun',
  chat: 'Sembang',
  online_bank_account: 'Akaun Bank Dalam Talian',
  x_is_required: '{{x}} diperlukan',
  bank: 'Bank',
  account_number: 'Nombor Akaun Bank',
  x_must_be_y_to_z_digits_in_length: '{{x}} mestilah panjang {{y}}-{{z}} digit',
  payment: 'Bayaran',
  total_payment: 'Jumlah Pembayaran',
  wrong_otp: 'OTP salah',
  refer_a_friend: 'Rujuk Rakan',
  apply_loan_details: 'Mohon Butiran Pinjaman',
  my_listing: 'Listing Saya',
  edit_profile: 'Edit Profil',
  extra_documents: 'Dokumen Tambahan',
  employer_name: 'Nama Majikan',
  employer_contact_number: 'Nombor Telefon Majikan',
  upload_payslip_for_last_month: 'Muat naik slip gaji untuk bulan lepas',
  upload_bank_statement_salary_last_month: 'Muat naik penyata gaji bank untuk bulan lepas',
  upload_utility_bill_last_month: 'Muat naik bil utiliti untuk bulan lepas',
  upload_active_mortgage_statement: 'Muat naik penyata gadai janji aktif',
  dont_worry_your_data_is_kept_protected:
    'Jangan risau, data anda disimpan mengikut undang-undang dan dilindungi oleh kami!',
  nric_front: 'NRIC Depan',
  upload_nric_front: 'Muat naik NRIC Depan',
  nric_back: 'NRIC Belakang',
  upload_nric_back: 'Muat naik NRIC Belakang',
  nric_number: 'Nombor NRIC',
  complete_profile_to_submit_loan_application: 'Sila lengkapkan profil anda untuk menyerahkan permohonan pinjaman.',
  name_as_per_ic: 'Nama Di IC',
  view_example: 'Lihat Contoh >',
  upload_video_selfie_together_with_nric_x_appname:
    'Muat naik Video Selfie bersama-sama dengan NRIC, Pegang IC sebut nama anda nak pinjam dengan {{x}}',
  emergency_contact_1: 'Orang Hubungan Kecemasan 1',
  emergency_contact_2: 'Orang Hubungan Kecemasan 2',
  relationship: 'Hubungan',
  name: 'Nama',
  done: 'Selesai',
  data_is_being_prep: 'Data sedang disediakan',
  loan_application_failed: 'Permohonan Pinjaman Gagal!',
  title_: 'Tajuk: ',
  message_: 'Mesej: ',
  contact_support: 'Hubungi Khidmat Pelanggan',
  ok: 'Ok',
  receive_method: 'Kaedah Terima',
  select_bank: 'Pilih Bank',
  key_in_account_number: 'Masukkan Nombor Akaun Bank',
  congratulations: 'Tahniah!',
  your_loan_has_been_approved: 'Pinjaman Anda Telah Diluluskan!',
  payment_amount_xcurrencyx: 'Amaun Pembayaran ({{x}})',
  interest: 'Minat',
  underwriting_fee: 'Yuran Pengunderaitan',
  gateway_fee: 'Yuran Gerbang',
  service_fee: 'Yuran Perkhidmatan',
  deposit: 'Deposit',
  total_amount_received: 'Jumlah amaun diterima',
  apply_now: 'Mohon Sekarang',
  application_submitted_wait_pic_to_serve_u:
    'Permohonan anda berjaya diserahkan. Sila tunggu orang yang bertanggungjawab untuk melayani anda.',
  mobile_number: 'Nombor Telefon Bimbit',
  _due_: ' Tamat: ',
  pay_now: 'Bayar Sekarang',
  recent_transactions: 'Transaksi Terbaru',
  no_loan_yet: 'Belum ada Pinjaman',
  no_notification: 'Tiada Pemberitahuan..',
  enter_any_payment_amount: 'Masukkan sebarang jumlah pembayaran',
  select_payment_method: 'Pilih Kaedah Pembayaran',
  invite_your_friend_to_x_app: 'Jemput rakan anda ke {{x}} app!',
  about_x: 'Tentang {{x}}',
  upload_receipt_x_will_verify_payment:
    'Sila muat naik gambar ATM / resit deposit tunai, {{x}} akan mengesahkan pembayaran anda dalam masa 3 hari bekerja.',
  pay_to_either_one_of_x_accounts:
    'Gunakan ATM atau Deposit Tunai untuk membayar salah satu daripada akaun {{x}} berikut:',
  youre_one_step_away_x_click_next_to_proceed:
    'Anda kini selangkah lagi daripada menikmati faedah {{x}}. Klik Seterusnya untuk meneruskan.',
  welcome_to_x: 'Selamat Datang Ke {{x}}',
  share_now: 'Kongsi Sekarang',
  copy: 'Salin',
  your_referral_history: 'Sejarah Rujukan Anda',
  share_your_referral_code: 'Kongsi Kod Rujukan Anda',
  how_referral_works: 'Cara Rujukan Berfungsi',
  copied: 'Sudah Disalin',
  how_referral_works_x_y_commission:
    'Formula komisen kami membolehkan rujukan memperoleh {{x}} dan {{y}} daripada pinjaman pertama dan pinjaman seterusnya bagi jemputan mereka. Komisen ini akan dikreditkan kepada rujukan apabila jemputan mereka telah menyelesaikan pembayaran balik penuh.',
  customer_support: 'Khidmat Pelanggan',
  date: 'Tarikh',
  amount: 'Amaun',
  status: 'Status',
  enter_payment_amount: 'Masukkan jumlah pembayaran',
  entered_amount_more_than_you_owe: 'Amaun yang dimasukkan adalah lebih daripada apa yang anda berhutang.',
  oops_we_are_sorry: 'Oops, Kami Minta Maaf',
  more_time_needed_will_inform_once_completed:
    'Lebih banyak masa diperlukan, kami akan memaklumkan anda sebaik sahaja ia selesai.',
  contact_us: 'Hubungi Kami',
  proceed_to_online_banking_to_pay_loan: 'Teruskan ke perbankan dalam talian untuk membayar pinjaman',
  proceed: 'Teruskan',
  thank_you_for_payment: 'Terima kasih atas pembayaran anda!',
  please_register_an_account: 'Sila daftar akaun',
  referral_code_optional: 'Kod Rujukan (Tidak Diwajibkan)',
  register: 'Daftar',
  downline_name: 'Nama Downline',
  no_: 'Tidak.',
  total_commission: 'Jumlah Komisen',
  total_withdrawal: 'Jumlah Keluaran',
  balance: 'Baki',
  total_register_: 'Jumlah Daftar: ',
  total_loans_: 'Jumlah Pinjaman: ',
  upload_your_receipt: 'Muat naik resit anda',
  email_address: 'Alamat E-mel',
  password: 'Kata laluan',
  forget_password: 'Terlupa Kata Laluan',
  sign_in: 'Log Masuk',
  upload_photo: 'Muat naik Foto',
  title: 'Tajuk',
  edit: 'Edit',
  delete: 'Padam',
  view: 'Lihat',
  no: 'Tidak',
  yes: 'Ya',
  phone_number: 'Nombor Telefon',
  save: 'Simpan',
  forgot_password: 'Terlupa Kata Laluan',
  submit: 'Serah',
  please_verify_account_first: 'Sila Sahkan Akaun Dahulu',
  are_you_ready_to_nbe_a_part_of_the_family: 'Adakah anda bersedia untuk\nmenjadi sebahagian daripada keluarga kita.',
  full_name: 'Nama Penuh',
  verify_account: 'Sahkan Akaun',
  skip: 'Skip',
  next: 'Seterusnya',
  let_s_get_started: 'Mari mulakan',
  please_enter_otp: 'Sila Masukkan OTP yang dihantar ke nombor anda',
  new_password: 'Kata Laluan Baharu',
  please_enter_your_nnew_password_to_reset_your_password:
    'Sila masukkan\nkata laluan baharu anda untuk menetapkan semula kata laluan anda',
  confirm_password: 'Sahkan Kata Laluan',
  reset_password: 'Tetapkan Semula Kata Laluan',
  welcome_back_yo_ve_nbeen_missed: 'Selamat datang kembali anda\n telah dirindui!',
  let_s_sign_you_in: 'Mari Log masuk anda.',
  choose_your_language: 'Pilih bahasa Anda untuk bermula!',
  login_now: 'Log masuk sekarang.',
  sign_up: 'Daftar',
  log_in: 'Log Masuk',
  empty_field_found: 'Data Kosong Ditemui',
  invalid_x: '{{x}} tidak sah',
  invalid_nric_please_enter_x_format: 'Format NRIC tidak sah. Sila masukkan {{x}}',
  something_went_wrong_x: 'Sesuatu telah berlaku: {{x}}',
  something_went_wrong_pls_contact_support: 'Sesuatu telah berlaku. Sila hubungi sokongan.',
  enter_valid_mobile_no: 'Masukkan No. Telefon Bimbit yang Sah',
  record_already_exists: 'Rekod sudah wujud',
  x_already_exists: '{{x}} sudah wujud!',
  permission_turned_off_can_be_enabled_in_app_settings:
    'Nampaknya anda telah mematikan kebenaran yang diperlukan untuk ciri ini. Ia boleh didayakan di bawah tetapan App!',
  go_to_settings: 'Pergi Ke Tetapan',
  cancel: 'Batal',
  payment_info: 'Maklumat Pembayaran',
  no_amount_and_id_found: 'Tiada amaun dan ID',
  name_of_correspondence: 'Nama orang hubungan',
  number_of_correspondence: 'Nombor telefon orang hubungan',
  emergency_contact_1_2_x_shouldnt_be_the_same: 'Kenalan Kecemasan 1 dan 2 {{x}} tidak sepatutnya sama',
  referral_history: 'Sejarah Rujukan',
  withdrawal_history: 'Sejarah Pengeluaran',
  loan_packages: 'Pakej Pinjaman',
  ensure_settle_payment_b4_due_date_to_avoid_penalty:
    'Pastikan anda menjelaskan bayaran anda sebelum tarikh tamat tempohnya untuk mengelakkan penalti pembayaran.',
  period: 'Tempoh',
  identity_nverification: 'Pengesahan\n Identiti',
  threemin_nreview: '3 minit\n Semakan',
  threemin_nreceival: '3 minit\n Penerimaan',
  identity: 'Pengesahan',
  verification: 'Identiti',
  threemin: '3 minit',
  review: 'Semakan',
  receival: 'Penerimaan',
  nric_name: 'Nama NRIC',
  _days: ' Hari',
  details: 'Butiran',
  announcements: 'Pengumuman',
  no_internet_connection: 'Tiada sambungan internet',
  retry: 'Cuba Lagi',
  code_sent: 'Kod OTP Telah Dihantar',
  otp_verification_failed: 'Pengesahan OTP Gagal',
  invalid_credential: 'Kelayakan Tidak Sah',
  firebase_quota_exceeded: 'Melebihi Kuota OTP',
  wrong_otp_code: 'Kod OTP Salah',
  too_many_request_detected: 'Terlalu banyak permintaan dikesan, sila cuba sebentar lagi',
  account_submitted: 'Maklumat Akaun Dihantar',
  loan_application_submitted: 'Permohonan Pinjaman Sudah Dihantar',
  please_wait_our_admin_to_contact_you_soon: 'Sila tunggu admin kami akan menghubungi anda tidak lama lagi.',
  whats_app_no: 'WhatsApp Nombor',
  invite_message_x_referral_code_x: 'Hei, saya menjumpai apl pinjaman sifar faedah. {{x}} Kod Rujukan saya ialah {{y}}',
  nric_format_name: 'Nombor MyKad (Tanpa simbol sempang)',
  nric_min_length_x: 'Panjang minimum ialah {{x}}',
  select_country_region: 'Pilih Negara / Wilayah',
  request_contact_list_permission_reason:
    'We require access to your contact list to help detect and prevent fraud. By comparing your contacts against our database, we can identify any suspicious activity and help keep your account and personal information secure.',
  permission_request: 'Permintaan Kebenaran',
  app_wont_work_without_permission: 'Aplikasi tidak akan berfungsi tanpa kebenaran:',
  go_to_app_settings_allow_permission_manually: 'Sila pergi ke tetapan aplikasi dan benarkan secara manual',
  allow: 'Benarkan',
  take_photo_using_camera: 'Ambil Foto Menggunakan Kamera',
  select_photo_from_gallery: 'Pilih Foto daripada Galeri',
  select_pdf_file: 'Pilih Fail PDF',
  failed_to_attach_photo: 'Gagal Melampirkan Foto',
  signup_agree_privacy_policy: 'Dengan mendaftar, anda bersetuju menerima Dasar Privasi kami.',
  login_agree_privacy_policy: 'Dengan melog masuk, anda bersetuju menerima Dasar Privasi kami.',
  request_upload_photo_permission_reason:
    'Kami memerlukan kebenaran anda untuk memuat naik foto dari peranti anda ke pelayan kami dengan selamat untuk tujuan KYC. Kami tidak akan berkongsi foto anda dengan mana-mana pihak ketiga tanpa kebenaran anda yang jelas.',
  upload_failed_please_try_again: 'Muatnaik gagal. Sila cuba lagi',
  loan_payment_failed: 'Loan Payment Failed!',
};

export default my;
