import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { forwardRef, useMemo } from 'react';
// @mui
import { Box } from '@mui/material';
import usePageTitle from '../hooks/usePageTitle';
import { HEADER } from '../config';
import Image from './Image';

// ----------------------------------------------------------------------

const Page = forwardRef(
  ({ children, title = '', isSubPage = false, isTranslate = true, hideMenu = null, meta, ...other }, ref) => {
    usePageTitle({ title, isSubPage, isTranslate, hideMenu });
    return (
      <>
        <Helmet>
          <title>{`${title}`}</title>
          {meta}
        </Helmet>

        <Box ref={ref} {...other} sx={{ marginTop: `${HEADER.MOBILE_HEIGHT + 5}px`, mx: '10px', ...other?.sx }}>
          {children}
        </Box>
      </>
    );
  }
);

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  isSubPage: PropTypes.bool,
  isTranslate: PropTypes.bool,
  hideMenu: PropTypes.bool,
  showBgImage: PropTypes.bool,
  BgImageSx: PropTypes.object,
  meta: PropTypes.node,
};

export default Page;
