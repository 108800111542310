import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    // Home Routes
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'dashboard', element: <Dashboard /> },
        { path: 'congratulations', element: <CongratulationsPage /> },
        { path: 'loanfailed', element: <ApplicationFailedPage /> },
        { path: 'loanpackage', element: <LoanPackageDetails /> },
        { path: 'applyloan', element: <ApplyLoanPage /> },
        { path: 'loansubmitted', element: <LoanSubmittedPage /> },
        { path: 'waitedtoolong', element: <WaitedTooLongPage /> },
        { path: 'afterloanpayment', element: <AfterLoanPaymentPage /> },
      ],
    },

    // Loan Routes
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'loan', element: <Loans /> },
        { path: 'loan/pay', element: <PayLoan /> },
        { path: 'loan/payInfo', element: <PaymentInfo /> },
        { path: 'loan/online-payment', element: <PaymentUrlWrapper /> },
      ],
    },

    // Notification Routes
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'notification', element: <Notifications /> },
        { path: 'notification/view', element: <ViewNotification /> },
      ],
    },

    // Profile Routes
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'profile', element: <Profile /> },
        { path: 'profile/kyc', element: <KYCPage /> },
        { path: 'profile/extradocs', element: <ExtraDocuments /> },
        { path: 'profile/referFriend', element: <ReferFriend /> },
        { path: 'profile/referralHistory', element: <ReferralHistory /> },
        { path: 'profile/aboutUs', element: <AboutUs /> },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// HOME
const Dashboard = Loadable(lazy(() => import('../views/dashboard/Dashboard')));
const CongratulationsPage = Loadable(lazy(() => import('../views/dashboard/CongratulationsPage')));
const ApplicationFailedPage = Loadable(lazy(() => import('../views/dashboard/ApplicationFailedPage')));
const LoanPackageDetails = Loadable(lazy(() => import('../views/dashboard/LoanPackageDetails')));
const ApplyLoanPage = Loadable(lazy(() => import('../views/dashboard/ApplyLoanPage')));
const LoanSubmittedPage = Loadable(lazy(() => import('../views/dashboard/LoanSubmittedPage')));
const WaitedTooLongPage = Loadable(lazy(() => import('../views/dashboard/WaitedTooLongPage')));
const AfterLoanPaymentPage = Loadable(lazy(() => import('../views/dashboard/AfterLoanPaymentPage')));

// LOANS
const Loans = Loadable(lazy(() => import('../views/loans/Loans')));
const PayLoan = Loadable(lazy(() => import('../views/loans/PayLoan')));
const PaymentInfo = Loadable(lazy(() => import('../views/loans/PaymentInfo')));
const PaymentUrlWrapper = Loadable(lazy(() => import('../views/loans/PaymentUrlWrapper')));

// NOTIFICATIONS
const Notifications = Loadable(lazy(() => import('../views/notifications/Notifications')));
const ViewNotification = Loadable(lazy(() => import('../views/notifications/ViewNotification')));

// ACCOUNT
const Profile = Loadable(lazy(() => import('../views/profile/Profile')));
const KYCPage = Loadable(lazy(() => import('../views/profile/KYCPage')));
const ExtraDocuments = Loadable(lazy(() => import('../views/profile/ExtraDocuments')));
const ReferFriend = Loadable(lazy(() => import('../views/profile/ReferFriend')));
const ReferralHistory = Loadable(lazy(() => import('../views/profile/ReferralHistory')));
const AboutUs = Loadable(lazy(() => import('../views/profile/AboutUs')));

// MAIN
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
